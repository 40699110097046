import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Icon from '@shared/ui/icons/Icon';
import Button from '@shared/ui/buttons/Button';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { Title as TitleComponent } from '@components/styled/Typography';
import { ReactComponent as NotFoundIconComponent } from '@icons/error/not-found.svg';
import { ReactComponent as UnknownErrorIconComponent } from '@icons/error/unknown-error.svg';
import { ReactComponent as ArrowIcon } from '@icons/wolfkit-light/arrow-left-light.svg';
import useAppNavigation from '@utils/hooks/useAppNavigation';
const ErrorContainer = styled(ContainerColumn)(() => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const NotFoundIcon = styled(NotFoundIconComponent)(() => ({
    width: 291,
    height: 117,
}));
const UnknownErrorIcon = styled(UnknownErrorIconComponent)(() => ({
    width: 320,
    height: 173,
}));
const Title = styled(TitleComponent)(props => ({
    fontSize: 48,
    fontWeight: 600,
    marginBottom: props.theme.spacing_sizes.l,
    textAlign: 'center',
    lineHeight: '64px',
    letterSpacing: 'unset',
}));
const IconContainer = styled.div(props => ({
    height: props.height,
    marginBottom: props.theme.spacing_sizes.xs * 6.75,
}));
const ButtonsContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.m,
    justifyContent: 'center',
    height: 'auto',
}));
const ErrorComponent = ({ variant = undefined, }) => {
    const { t } = useTranslation();
    const { navigate, routes, rrdNavigate } = useAppNavigation();
    const handleReload = () => {
        window.location.reload();
    };
    const goToPrevPage = useCallback(() => {
        rrdNavigate(-1);
    }, [rrdNavigate]);
    const goToHome = useCallback(() => {
        navigate(routes.ROOT);
    }, [navigate, routes]);
    const { icon, title, buttons } = useMemo(() => {
        switch (variant) {
            case 'not_found':
                return {
                    icon: (_jsx(IconContainer, { height: 117, children: _jsx(Icon, { IconComponent: NotFoundIcon, keepOriginalColor: true }) })),
                    title: _jsx(Title, { children: t('errors.not_found.title') }),
                    buttons: (_jsxs(ButtonsContainer, { children: [_jsx(Button, { variant: 'tinted', color: 'primary', size: 'medium', onClick: goToPrevPage, startIcon: ArrowIcon, children: t('errors.not_found.back_btn') }), _jsx(Button, { variant: 'filled', color: 'primary', size: 'medium', onClick: goToHome, children: t('errors.not_found.home_btn') })] })),
                };
            case 'unknown_error':
                return {
                    icon: (_jsx(IconContainer, { height: 173, children: _jsx(Icon, { IconComponent: UnknownErrorIcon, keepOriginalColor: true }) })),
                    title: _jsx(Title, { children: t('errors.unknown_error.title') }),
                    buttons: (_jsxs(ButtonsContainer, { children: [_jsx(Button, { variant: 'tinted', color: 'primary', size: 'medium', onClick: goToPrevPage, startIcon: ArrowIcon, children: t('errors.unknown_error.back_btn') }), _jsx(Button, { variant: 'filled', color: 'primary', size: 'medium', onClick: handleReload, children: t('errors.unknown_error.reload_btn') })] })),
                };
            default:
                return { icon: null, buttons: null, title: null };
        }
    }, [
        variant,
        goToHome,
        goToPrevPage,
        t,
    ]);
    return (_jsxs(ErrorContainer, { children: [icon, title, buttons] }));
};
export default ErrorComponent;
