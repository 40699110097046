import { jsx as _jsx } from "react/jsx-runtime";
import { useRouteError } from 'react-router-dom';
import { useMemo } from 'react';
import ErrorComponent from '../ErrorComponent';
import { ErrorTypesEnum } from '../constants';
const RouterErrorElement = () => {
    const error = useRouteError();
    const errorVariant = useMemo(() => {
        if (!error) {
            return undefined;
        }
        switch (error.errorType) {
            case ErrorTypesEnum.NotFound:
                return 'not_found';
            case ErrorTypesEnum.UnknownError:
            case ErrorTypesEnum.Restricted:
            default:
                return 'unknown_error';
        }
    }, [
        error,
    ]);
    if (!error) {
        return null;
    }
    return (_jsx(ErrorComponent, { variant: errorVariant }));
};
export default RouterErrorElement;
