export var ErrorTypesEnum;
(function (ErrorTypesEnum) {
    ErrorTypesEnum["NotFound"] = "NOT_FOUND";
    ErrorTypesEnum["UnknownError"] = "UNKNOWN_ERROR";
    ErrorTypesEnum["Restricted"] = "RESTRICTED";
})(ErrorTypesEnum || (ErrorTypesEnum = {}));
export class ApplicationError extends Error {
    constructor(params) {
        super(params.message);
        this.errorType = params.errorType;
    }
}
export default ApplicationError;
