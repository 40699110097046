import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Typography, useTheme } from '@mui/material';
import { ReactComponent as ErrorIcon } from '@icons/wolfkit-solid/alert-circle-solid.svg';
import Icon from '@shared/ui/icons/Icon';
const ErrorContainer = styled.div `
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: ${props => props.theme.spacing_sizes.xs}px;
`;
const ErrorText = styled(Typography) `
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${props => props.theme.palette.error.main};
`;
const ErrorMessage = ({ className = undefined, errorMessage = '', }) => {
    const theme = useTheme();
    return (_jsxs(ErrorContainer, { className: className, children: [_jsx(Icon, { size: 14, color: theme.palette.error.main, IconComponent: ErrorIcon }), _jsx(ErrorText, { children: errorMessage })] }));
};
export default ErrorMessage;
